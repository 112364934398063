.security-section p {
    margin-top: 0;
    margin-bottom: 0 !important;
  }
  .security-icon-included {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  .security-icon-included-three {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    justify-content: space-between;
  }
  .security-icon-image {
    max-width: 100%;
  }
  .security-icon-image-2 {
  }
  .security-icon-image-3 {
    width: 20rem;
    padding-top: 2.5rem;
    padding-left: 1rem;
  }
  .security-pic-1-mobile {
    display: none;
  }
  .security-point-2-txt-container {
    padding-left: 150px;
    padding-top: 2rem;
  }
  .security-point-2-txt-img {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  h1 {
    color: #03254c !important;
  }
  h2 {
    color: #03254c !important;
  }
  h3 {
    color: #03254c !important;
  }
  h4 {
    color: #03254c !important;
  }
  @media only screen and (max-width: 991px) {
    br {
      display: none;
    }
    img {
      max-width: 100%;
    }
    .security-pic-1 {
      display: none;
    }
    .security-pic-1-mobile {
      display: flex;
      justify-content: center;
      margin: auto;
      width: 100%;
    }
    .security-icon-included {
      display: block;
    }
    .security-point-2-txt-container {
      padding-left: 0;
      padding-bottom: 3rem;
    }
    .security-icon-image-2 {
      max-width: 100%;
      display: flex;
      justify-content: center;
      margin: auto;
    }
    .security-icon-image-3 {
      height: 8rem;
      width: auto !important;
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 1rem;
    }
    .security-icon-included-three {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      width: 100%;
    }
  }
  