@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  font-style: normal;
}

.manrope {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}


#animatedText span {
  opacity: 0;
  display: inline-block;
  white-space: nowrap;
  transition: opacity 0.7s ease, transform 0.7s ease;
}

#animatedText span.angle1 {
  transform: translateY(-50px) rotate(-15deg) scale(0.8);
}

#animatedText span.angle2 {
  transform: translateY(50px) rotate(15deg) scale(0.8);
}

#animatedText span.angle3 {
  transform: translateX(-50px) rotate(-15deg) scale(0.8);
}

#animatedText span.angle4 {
  transform: translateX(50px) rotate(15deg) scale(0.8);
}

#animatedText span.visible {
  opacity: 1;
  transform: translateY(0) rotate(0) scale(1);
}

/* Keyframe for the left-to-right reveal */
.ml3 {
  font-weight: 900;
  font-size: 2.15rem;
}

.slick-list .slick-track .slick-slide .item-slide{
  display: flex !important;
}